













// Components
import { Component, Vue } from 'vue-property-decorator';
import { getComponent } from '@/utils/helpers';

@Component({
  components: {
    GeneralLayout: () => getComponent('common/GeneralLayout'),
    CentralLoggingErrorsChart: () => getComponent('centralLogging/CentralLoggingErrorsChart'),
    ReliabilityByDate: () => getComponent('metrics/ReliabilityByDate'),
    BugsnagErrorsChart: () => getComponent('bugsnag/BugsnagErrorsChart'),
    CentralLoggingTable: () => getComponent('centralLogging/CentralLoggingTable'),
    CentralLoggingTracebackTable: () => getComponent('centralLogging/CentralLoggingTracebackTable'),
  },
})
export default class DailySummary extends Vue {}
